import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Button, Divider, Box, Grid, Typography } from '@mui/material';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';

class Separator extends Component {
	constructor(props) {
		super(props);

		this.state = {
			collapse: props.collapse,
			open: props.collapse_open,
		};
	}
	render() {
		return (
			<React.Fragment>
				{this.props.collapse && this.props.text ? (
					<Box className={'generic-text-align-end'} style={{ width: '100%' }}>
						<Button
							color="primary"
							onClick={() => {
								this.setState({ open: !this.state.open });
							}}
							startIcon={this.state.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						>
							{this.props.text}
						</Button>
					</Box>
				) : !this.props.collapse && this.props.text ? (
					<Typography variant="button" color="primary" className="MuiButton-text">
						{this.props.text}
					</Typography>
				) : null}
				{this.props.divider ? <Divider className="generic-divider-main-color generic-margin-bottom-2" style={{ width: '100%' }} /> : null}
				<Collapse in={this.state.open || !this.state.collapse} style={{ width: '100%' }}>
					<Grid container item spacing={2}>
						{this.props.children}
					</Grid>
				</Collapse>
			</React.Fragment>
		);
	}
}

export default Separator;

Separator.propTypes = {
	id: PropTypes.string.isRequired,
	text: PropTypes.string,
	collapse: PropTypes.bool.isRequired,
	divider: PropTypes.bool,
	fields: PropTypes.array.isRequired,
};
