import WIDGETS from './widgets';

export default {
  spacing: 0,
	direction: 'column',
	widgets: [
		{ content: WIDGETS.GOBACKWEIGHT, breakpoints: { xs: 12, sm: 12 }, lazy_loading: 0 },
		{ content: WIDGETS.UPDATEWEIGHT, breakpoints: { xs: 12, sm: 12 }, lazy_loading: 0 },
		{ content: WIDGETS.LOGSWEIGHT, breakpoints: { xs: 12, md: 12 }, lazy_loading: 0 },
	],
};
