import WIDGETS from './widgets';
import { store } from '../store.js';
import { getTranslate } from 'react-localize-redux';

const state = store.getState();
const translate = getTranslate(state.localize);

export default {
	spacing: 0,
	widgets: [
		{
			content: WIDGETS.PAGE_TITLE,
			breakpoints: { xs: 12 },
			lazy_loading: 0,
			component_props: { translations: { title: 'ticket_overview_title' }, redux: { subtitle: 'tickets.ticket.condition.name' } },
		},
		{ content: WIDGETS.TICKET_OVERVIEW, breakpoints: { xs: 12 }, lazy_loading: 0 },
	],
};
