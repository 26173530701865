import React from 'react';
import { CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { connect } from 'react-redux';

import PlaceholderIMG from '../../assets/images/placeholder.svg';

const useStyles = makeStyles((theme) => createStyles({
	'fullsize-loading-wrapper': {
		backgroundColor: 'white !importante',
		height: '100vh !important',
		justifyContent: 'center',
		textAlign: 'center',
		zIndex: '9999999',
	},
	'fullsize-loading-wrapper-size': {
		backgroundColor: 'white',
		height: '60%',
		width: '100%',
		justifyContent: 'center',
		textAlign: 'center',
		zIndex: '9999999',
		margin: '16px',
	},
	'fullsize-loading-logo-wrapper': {
		height: '70vh',
		justifyContent: 'center',
		textAlign: 'center',
		zIndex: '9999999',
		display: 'flex',
		alignItems: 'end',
	},
	'fullsize-loading-app-logo': {
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		height: '30%',
	},
	'fullsize-loading-spinner-wrapper': {
		height: '30vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		zIndex: '9999999',
	},
	'fullsize-loading-spinner': {
		width: '50px',
		//position: 'absolute',
		height: '50px',
		top: '70%',
	},
	'fullsize-loading-logo': {
		[theme.breakpoints.down('xl')]: {
			height: '100%',
			backgroundRepeat: 'no-repeat !important',
			backgroundPosition: 'center !important',
			backgroundSize: 'contain !important',
		},
		[theme.breakpoints.up('lg')]: {
			width: '100%',
			height: '100%',
			backgroundRepeat: 'no-repeat !important',
			backgroundPosition: 'center !important',
		},
	},
}));

function SplashScreen(props) {
	const classes = useStyles(props);

	return (
		<div className={classes['fullsize-loading-wrapper']}>
			<div className={classes['fullsize-loading-logo-wrapper']}>
				<div className={classes['fullsize-loading-wrapper-size']}>
					<div
						className={classes['fullsize-loading-logo']}
						style={{
							background:
								typeof props.settings.site !== 'undefined' && props.settings.site.files.splash_screen_logo
									? `url(${props.config.apihost + '/configuration/files/' + props.settings.site.files.splash_screen_logo.uuid})`
									: `url(${PlaceholderIMG})`,
						}}
					></div>
				</div>
			</div>
			<div className={classes['fullsize-loading-spinner-wrapper']}>
				<CircularProgress className={classes['fullsize-loading-spinner']} color="primary" />
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	config: state.config,
	settings: state.settings,
});

export default connect(mapStateToProps)(SplashScreen);
