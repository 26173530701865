import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField } from '@mui/material';
import { closeModalAction } from '../Generic/actions/genericActions';
import { exitElementFullscreen } from '../../helpers/functions/functions';

class Modal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			text: '',
		};
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.modal.open && this.props.modal.open) {
			exitElementFullscreen();
		}
	}

	componentWillUnmount() {
		this.props.closeModalAction();
	}

	render() {
		return (
			<Dialog
				open={this.props.modal.open}
				onClose={(event, reason) => {
					if (this.props.modal.disable_click_away && (reason == 'escapeKeyDown' || reason == 'backdropClick')) {
						return;
					} else {
						if (this.props.modal.action_on_close) {
							this.props.modal.action_on_close();
						}
						this.props.closeModalAction();
					}
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				{...(this.props.modal.max_width && { maxWidth: this.props.modal.max_width })}
			>
				<DialogTitle id="alert-dialog-title">{this.props.modal.title}</DialogTitle>
				{this.props.modal.description && (
					<DialogContent>
						<DialogContentText id="alert-dialog-description">{this.props.modal.description}</DialogContentText>
					</DialogContent>
				)}
				{this.props.modal.text && (
					<DialogContent>
						<DialogContentText id="alert-dialog-text">{this.props.modal.text}</DialogContentText>
					</DialogContent>
				)}
				{this.props.modal.input && (
					<DialogContent>
						<TextField
							id="modal_input"
							label={this.props.modal?.input_label}
							onChange={(event) => {
								console.log(event);
								this.setState({
									text: event.target.value,
								});
							}}
							variant="outlined"
							fullWidth
						/>
					</DialogContent>
				)}
				<DialogActions>
					{this.props.modal.show_button_no ? (
						<Button
							{...(this.props.modal.button_no_id && { id: this.props.modal.button_no_id })}
							onClick={() => {
								if (this.props.modal.action_no_button) {
									this.props.modal.action_no_button(this.props.modal.props_no_button, this.state.text);
								}
								this.props.closeModalAction();
							}}
							color="secondary"
						>
							{this.props.modal.button_no}
						</Button>
					) : (
						''
					)}
					{this.props.modal.show_button_yes ? (
						<Button
							{...(this.props.modal.button_yes_id && { id: this.props.modal.button_yes_id })}
							onClick={() => {
								if (this.props.modal.action) {
									this.props.modal.action(this.props.modal.props, this.state.text);
								}
								this.props.closeModalAction();
							}}
							color="primary"
							autoFocus
						>
							{this.props.modal.button_yes}
						</Button>
					) : (
						''
					)}
				</DialogActions>
			</Dialog>
		);
	}
}

const mapStateToProps = (state) => ({
	modal: state.generic.modal,
});

export default connect(mapStateToProps, { closeModalAction })(Modal);
