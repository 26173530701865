import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { enUS, sv, enGB } from 'date-fns/locale';
import { formatCodeDates } from '../../../helpers/functions/functions';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const localeMap = {
	'en-US': enUS,
	'sv-SE': sv,
  'en-GB': enGB
};

function RenderDateTimePicker({ input: { onChange, value }, meta: { invalid, touched, error, active }, ...custom }) {
  const translate = useSelector((state) => getTranslate(state.localize));
  const [errorType, setError] = React.useState(null);

	const warnings = {
		...(custom.inputdata.disablePast && { disablePast: translate('form_field_date_picker_warning_past_date') }),
		...(custom.inputdata.disableFuture && { disableFuture: translate('form_field_date_picker_warning_future_date') }),
	};

	return (
		<LocalizationProvider
			dateAdapter={AdapterDateFns}
			adapterLocale={Object.keys(localeMap).includes(custom.locale) ? localeMap[custom.locale] : localeMap['en-US']}
		>
			<DateTimePicker
				name={custom.inputdata.name}
				format={formatCodeDates(custom.locale, true)}
				label={custom.inputdata.label}
				onError={(newError) => setError(newError)}
				slotProps={{
					textField: {
						fullWidth: true,
						helperText: warnings[errorType],
						InputLabelProps: {
							classes: {
								error: warnings[errorType] && 'datepicker-warning',
							},
						},
						InputProps: {
              id: custom.inputdata.id,
							classes: {
								error: warnings[errorType] && 'datepicker-warning',
								notchedOutline: warnings[errorType] && 'datepicker-warning',
							},
						},
						FormHelperTextProps: {
							classes: { root: warnings[errorType] && 'datepicker-warning' },
						},
					},
				}}
				inputVariant="outlined"
				onChange={(date) => {
					if (typeof custom.inputdata.onChange !== 'undefined') {
						custom.inputdata.onChange(date);
					}
					onChange(date);
				}}
				value={value === '' ? null : value}
				ampm={false}
				disableFuture={custom.inputdata.disableFuture}
				disablePast={custom.inputdata.disablePast}
				disabled={custom.inputdata.readonly}
				error={touched && invalid && !active}
				helperText={touched && !active && error}
				openTo={custom.inputdata.openTo}
			/>
		</LocalizationProvider>
	);
}

export default RenderDateTimePicker;
