import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import { connect } from 'react-redux';
import CatalogueGrid from './CatalogueGrid';
import genericActions from '../Generic/actions/genericActions';
import { catalogue_structure } from '../../catalogues';

export const SideBar = ({ open, catalogue, handleSideBar, drawer_props = {} }) => {
	return (
		<Drawer
			anchor={'right'}
			open={open}
			onClose={() => handleSideBar(false)}
      classes={{ paper: 'generic-sidebar-main' }}
			PaperProps={{
				sx: { width: { xs: '100%', sm: 'auto' }, minWidth: { xs: '100%', sm: '35%' }, maxWidth: { xs: '100%', sm: '35%' } },
			}}
			{...drawer_props}
		>
			<CatalogueGrid catalogue={catalogue} />
		</Drawer>
	);
};

SideBar.propTypes = {
	open: PropTypes.bool.isRequired,
	catalogue: PropTypes.shape(catalogue_structure),
	handleSideBar: PropTypes.func.isRequired,
	drawer_props: PropTypes.shape({
		anchor: PropTypes.string,
		classes: PropTypes.object,
		PaperProps: PropTypes.object,
		ModalProps: PropTypes.object,
		SlideProps: PropTypes.object,
		variant: PropTypes.string,
		sx: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
			PropTypes.func,
			PropTypes.object,
		]) /** MUI sx prop */,
	}),
};

const mapStateToProps = (state) => ({
	open: state.generic.side_bar.open,
	catalogue: state.generic.side_bar.catalogue,
	drawer_props: state.generic.side_bar.drawer_props,
});

const mapDispatchToProps = { handleSideBar: genericActions.handleSideBar };

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
