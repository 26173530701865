import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Typography, Grid, Container } from '@mui/material';
import { createNewAccount } from '../Login/actions/loginActions';
import { verifySsnAction } from '../Users/actions/usersActions';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Main from '../Generic/Form/Main.js';
import { push } from 'connected-react-router';
import NavBar from '../Welcome/NavBar';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { formValueSelector } from 'redux-form';
import { MessageToastLogin } from '../Generic';
import { my_ssn_validations } from '../../helpers/functions/functions';
import Alert from '@mui/material/Alert';
import { TYPES as FORM_TYPES } from '../Generic/FormTypes';
import BlockUI from '../Layout/BlockUI';
class CreateNewAccount extends Component {
	state = {
		consented: this.props.user !== null ? this.props.user.consented : 0,
		password: '',
		confirmPassword: '',
		match: null,
		charNumberValid: false,
		specialCharValid: false,
		uppercaseValid: false,
		numberValid: false,
		visibility: false,
		valid: false,
		search: false,
		searching: false,
		ssn: null,
		showCollapse: false,
		showAlert: false,
	};

	// Check the length of the input
	checkPasswordLength = (password) => {
		if (
			typeof this.props.settings.site.password_complexity === 'undefined' ||
			this.props.settings.site.password_complexity === null ||
			password.length >= this.props.settings.site.password_complexity.length ||
			!this.props.settings.site.password_complexity.length
		) {
			this.setState({
				charNumberValid: true,
			});
		} else {
			this.setState({
				charNumberValid: false,
			});
		}
	};

	// Check for special characters
	checkSpecialCharacters = (password) => {
		const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
		const valSC = (password.match(pattern) || []).length;
		if (
			typeof this.props.settings.site.password_complexity === 'undefined' ||
			this.props.settings.site.password_complexity === null ||
			valSC >= this.props.settings.site.password_complexity.special_characters ||
			!this.props.settings.site.password_complexity.special_characters
		) {
			this.setState({
				specialCharValid: true,
			});
		} else {
			this.setState({
				specialCharValid: false,
			});
		}
	};

	// Check for an uppercase character
	checkUppercase = (password) => {
		const pattern = /[A-Z]/g;
		const valU = (password.match(pattern) || []).length;

		if (
			typeof this.props.settings.site.password_complexity === 'undefined' ||
			this.props.settings.site.password_complexity === null ||
			valU >= this.props.settings.site.password_complexity.uppercase ||
			!this.props.settings.site.password_complexity.uppercase
		) {
			this.setState({
				uppercaseValid: true,
			});
		} else {
			this.setState({
				uppercaseValid: false,
			});
		}
	};

	// Check for a number
	checkNumber = (password) => {
		const pattern = /[0-9]/g;
		const valN = (password.match(pattern) || []).length;
		if (
			typeof this.props.settings.site.password_complexity === 'undefined' ||
			this.props.settings.site.password_complexity === null ||
			valN >= this.props.settings.site.password_complexity.numbers ||
			!this.props.settings.site.password_complexity.numbers
		) {
			this.setState({
				numberValid: true,
			});
		} else {
			this.setState({
				numberValid: false,
			});
		}
	};
	handlePasswordChange = (value) => {
		this.checkPasswordLength(value);
		this.checkSpecialCharacters(value);
		this.checkUppercase(value);
		this.checkNumber(value);
		this.setState({ valid: this.props.password_verify === value });
	};

	handleSubmitButton = (form) => {
		this.props.createNewAccount(form);
	};

	clearSsn = (ssn) => {
		this.setState({
			search: false,
			searching: false,
			showCollapse: false,
			ssn: ssn,
		});
	};

	verifySsn = () => {
		this.setState((state) => ({
			...state,
			searching: true,
			search: true,
			invalid_ssn: false,
		}));
		this.props.verifySsnAction('newuser', (data) => {
			switch (data) {
				case 'already_register':
					this.setState({
						search: true,
						searching: false,
						showCollapse: false,
						showAlert: true,
					});
					break;
				case 'unable_to_search':
					this.setState({
						search: true,
						searching: false,
						showCollapse: false,
						showAlert: false,
					});
					break;
				default:
					this.setState({
						search: true,
						searching: false,
						showCollapse: true,
						showAlert: false,
					});
					break;
			}
		});
	};

	handleConfig = () => {
		const { translate } = this.props;

		const structure = {
			form: 'newuser',
			id: 'registeruser-form',
			title: '',
			subtitle: '',
			helperText: '',
			maxWidth: 'lg',
			backButtonWidth: 'sm',
			buttons_grid_layout: { justify_content: 'center' },
			columns: [
				{
					id: 'column-1',
					text: '',
					grid_layout: { xs: 12, md: 12 },
					separators: [
						{
							id: 'separator-1',
							text: '',
							collapse: false,
							divider: false,
							fields: [
								{
									id: 'ssn',
									name: 'ssn',
									label: translate('ticket_form_invite_field_ssn'),
									placeholder: translate('ssn_field_placeholder_' + this.props.settings.auth.ssnValidation),
									type:
										this.props.registration_fields.find((elem) => elem.field == 'ssn') &&
										this.props.registration_fields.find((elem) => elem.field == 'ssn').display
											? FORM_TYPES.INPUT_ADORNMENT
											: FORM_TYPES.NONE,
									validate: {
										required:
											this.props.registration_fields.find((elem) => elem.field == 'ssn') &&
											this.props.registration_fields.find((elem) => elem.field == 'ssn').required,
										...(this.props.settings.auth.ssnValidation
											? {
													functionValidation: [
														{
															validation: (value) =>
																typeof my_ssn_validations[this.props.settings.auth.ssnValidation] !== 'undefined'
																	? my_ssn_validations[this.props.settings.auth.ssnValidation](value)
																	: true,
														},
													],
											  }
											: {}),
									},
									grid_layout: { xs: 12, md: 6 },
									onValidValue: () => {
										if (!this.state.search) {
											this.verifySsn();
										}
									},
									adornment: {
										action: () => this.verifySsn(),
										triggered: this.state.searching,
									},
									onChange: (value) => this.clearSsn(value),
									initialvalue: this.state.ssn ? this.state.ssn : '',
								},
								{
									id: 'redirect',
									type: this.state.showAlert ? FORM_TYPES.EXTERNAL : FORM_TYPES.HIDDEN,
									grid_layout: { xs: 12, md: 6 },
									content: (
										<Alert
											severity="warning"
											className="generic-pointer"
											onClick={() => {
												this.props.push('/login');
											}}
										>
											{translate('creat_alert_notification')}
										</Alert>
									),
								},
							],
						},
						...(typeof this.props.settings.feature !== 'undefined' &&
						this.props.settings.feature.insurances &&
						this.props.registration_fields.find((elem) => elem.field == 'insurance') &&
						this.props.registration_fields.find((elem) => elem.field == 'insurance').display &&
						((this.state.showCollapse &&
							this.props.registration_fields.find((elem) => elem.field == 'ssn') &&
							this.props.registration_fields.find((elem) => elem.field == 'ssn').display &&
							!this.state.showAlert) ||
							(this.props.registration_fields.find((elem) => elem.field == 'ssn') &&
								!this.props.registration_fields.find((elem) => elem.field == 'ssn').display))
							? [
									{
										id: 'separator-2',
										text: translate('users_register_form_collapse_insurance_title'),
										collapse: false,
										collapse_open: false,
										divider: true,
										fields: [
											{
												id: 'insurance_company',
												name: 'insurance_company',
												label: translate('users_register_form_myprofile_field_insurance_company'),
												type:
													this.props.registration_fields.find((elem) => elem.field == 'insurance') &&
													this.props.registration_fields.find((elem) => elem.field == 'insurance').display
														? FORM_TYPES.INPUT
														: FORM_TYPES.NONE,
												validate: {
													required:
														this.props.registration_fields.find((elem) => elem.field == 'insurance') &&
														this.props.registration_fields.find((elem) => elem.field == 'insurance').required,
												},
												grid_layout: { xs: 12, md: 4 },
                        initialvalue: this.props.login.client.name,
												readonly: true,
											},
											{
												id: 'insurance_number',
												name: 'insurance_number',
												label: translate('users_register_form_myprofile_field_insurance_number'),
												type:
													this.props.registration_fields.find((elem) => elem.field == 'insurance') &&
													this.props.registration_fields.find((elem) => elem.field == 'insurance').display
														? FORM_TYPES.NUMBER
														: FORM_TYPES.NONE,
												disableNumberArrows: true,
												validate: {
													required:
														this.props.registration_fields.find((elem) => elem.field == 'insurance') &&
														this.props.registration_fields.find((elem) => elem.field == 'insurance').required,
												},
												grid_layout: { xs: 12, md: 4 },
											},
											{
												id: 'insurance_valid_date',
												name: 'insurance_valid_date',
												label: translate('users_register_form_myprofile_field_insurance_valid_date'),
												type:
													this.props.registration_fields.find((elem) => elem.field == 'insurance') &&
													this.props.registration_fields.find((elem) => elem.field == 'insurance').display
														? FORM_TYPES.DATEPICKER
														: FORM_TYPES.NONE,
												disablePast: true,
												openTo: 'year',
												validate: {
													required:
														this.props.registration_fields.find((elem) => elem.field == 'insurance') &&
														this.props.registration_fields.find((elem) => elem.field == 'insurance').required,
												},
												grid_layout: { xs: 12, md: 4 },
											},
										],
									},
							  ]
							: []),
						...((this.state.showCollapse &&
							this.props.registration_fields.find((elem) => elem.field == 'ssn') &&
							this.props.registration_fields.find((elem) => elem.field == 'ssn').display &&
							!this.state.showAlert) ||
						(this.props.registration_fields.find((elem) => elem.field == 'ssn') &&
							!this.props.registration_fields.find((elem) => elem.field == 'ssn').display)
							? [
									{
										id: 'separator-3',
										text:
											(typeof this.props.settings.feature !== 'undefined' && this.props.settings.feature.insurances) ||
											(this.props.registration_fields.find((elem) => elem.field == 'ssn') &&
												this.props.registration_fields.find((elem) => elem.field == 'ssn').display)
												? translate('users_register_form_collapse_title')
												: '',
										collapse:
											(typeof this.props.settings.feature !== 'undefined' && this.props.settings.feature.insurances) ||
											(this.props.registration_fields.find((elem) => elem.field == 'ssn') &&
												this.props.registration_fields.find((elem) => elem.field == 'ssn').display),
										collapse_open: true,
										divider:
											(typeof this.props.settings.feature !== 'undefined' && this.props.settings.feature.insurances) ||
											(this.props.registration_fields.find((elem) => elem.field == 'ssn') &&
												this.props.registration_fields.find((elem) => elem.field == 'ssn').display),
										fields: [
											{
												id: 'first_name',
												name: 'first_name',
												label: translate('users_register_form_myprofile_field_first_name'),
												type:
													this.props.registration_fields.find((elem) => elem.field == 'first_name') &&
													this.props.registration_fields.find((elem) => elem.field == 'first_name').display
														? FORM_TYPES.INPUT
														: FORM_TYPES.NONE,
												validate: {
													required:
														this.props.registration_fields.find((elem) => elem.field == 'first_name') &&
														this.props.registration_fields.find((elem) => elem.field == 'first_name').required,
												},
												grid_layout: { xs: 12, md: 6 },
											},
											{
												id: 'last_name',
												name: 'last_name',
												label: translate('users_register_form_myprofile_field_last_name'),
												type:
													this.props.registration_fields.find((elem) => elem.field == 'last_name') &&
													this.props.registration_fields.find((elem) => elem.field == 'last_name').display
														? FORM_TYPES.INPUT
														: FORM_TYPES.NONE,
												validate: {
													required:
														this.props.registration_fields.find((elem) => elem.field == 'last_name') &&
														this.props.registration_fields.find((elem) => elem.field == 'last_name').required,
												},
												grid_layout: { xs: 12, md: 6 },
											},
											{
												id: 'gender',
												name: 'gender',
												label: translate('users_register_form_myprofile_field_gender'),
												type:
													this.props.registration_fields.find((elem) => elem.field == 'gender') &&
													this.props.registration_fields.find((elem) => elem.field == 'gender').display
														? FORM_TYPES.SELECT
														: FORM_TYPES.NONE,
												validate: {
													required:
														this.props.registration_fields.find((elem) => elem.field == 'gender') &&
														this.props.registration_fields.find((elem) => elem.field == 'gender').required,
												},
												grid_layout: { xs: 12, md: 6 },
												options: [
													{
														label: translate('users_register_form_myprofile_field_gender_option_male'),
														value: 'male',
													},
													{
														label: translate('users_register_form_myprofile_field_gender_option_female'),
														value: 'female',
													},
													{
														label: translate('users_register_form_personal_data_field_gender_option_other'),
														value: 'unknown',
													},
												],
											},
											{
												id: 'birthdate',
												name: 'birthdate',
												label: translate('users_register_form_personal_data_field_birthdate'),
												type:
													this.props.registration_fields.find((elem) => elem.field == 'birthdate') &&
													this.props.registration_fields.find((elem) => elem.field == 'birthdate').display
														? FORM_TYPES.DATEPICKER
														: FORM_TYPES.NONE,
												openTo: 'year',
												disableFuture: true,
												validate: {
													required:
														this.props.registration_fields.find((elem) => elem.field == 'birthdate') &&
														this.props.registration_fields.find((elem) => elem.field == 'birthdate').required,
												},
												grid_layout: { xs: 12, md: 6 },
											},
											{
												id: 'address',
												name: 'address',
												label: translate('users_register_form_myprofile_field_address'),
												type:
													this.props.registration_fields.find((elem) => elem.field == 'address') &&
													this.props.registration_fields.find((elem) => elem.field == 'address').display
														? FORM_TYPES.INPUT
														: FORM_TYPES.NONE,
												validate: {
													required:
														this.props.registration_fields.find((elem) => elem.field == 'address') &&
														this.props.registration_fields.find((elem) => elem.field == 'address').required,
												},
												grid_layout: { xs: 12, md: 6 },
											},
											{
												id: 'zip_code',
												name: 'zip_code',
												label: translate('users_register_form_myprofile_field_zip_code'),
												type:
													this.props.registration_fields.find((elem) => elem.field == 'zip_code') &&
													this.props.registration_fields.find((elem) => elem.field == 'zip_code').display
														? FORM_TYPES.INPUT
														: FORM_TYPES.NONE,
												validate: {
													required:
														this.props.registration_fields.find((elem) => elem.field == 'zip_code') &&
														this.props.registration_fields.find((elem) => elem.field == 'zip_code').required,
												},
												grid_layout: { xs: 12, md: 6 },
											},
											{
												id: 'city',
												name: 'city',
												label: translate('users_register_form_myprofile_field_city'),
												type:
													this.props.registration_fields.find((elem) => elem.field == 'city') &&
													this.props.registration_fields.find((elem) => elem.field == 'city').display
														? FORM_TYPES.INPUT
														: FORM_TYPES.NONE,
												validate: {
													required:
														this.props.registration_fields.find((elem) => elem.field == 'city') &&
														this.props.registration_fields.find((elem) => elem.field == 'city').required,
												},
												grid_layout: { xs: 12, md: 6 },
											},
											{
												id: 'phone',
												name: 'phone',
												label: translate('users_register_form_myprofile_field_phone'),
												type:
													this.props.registration_fields.find((elem) => elem.field == 'phone') &&
													this.props.registration_fields.find((elem) => elem.field == 'phone').display
														? FORM_TYPES.NUMBER
														: FORM_TYPES.NONE,
												disableNumberArrows: true,
												validate: {
													required:
														this.props.registration_fields.find((elem) => elem.field == 'phone') &&
														this.props.registration_fields.find((elem) => elem.field == 'phone').required,
												},
												grid_layout: { xs: 12, md: 6 },
											},
											{
												id: 'email',
												name: 'email',
												label: translate('users_register_form_myprofile_field_email'),
												type:
													this.props.registration_fields.find((elem) => elem.field == 'email') &&
													this.props.registration_fields.find((elem) => elem.field == 'email').display
														? FORM_TYPES.INPUT
														: FORM_TYPES.NONE,
												validate: {
													required:
														this.props.registration_fields.find((elem) => elem.field == 'email') &&
														this.props.registration_fields.find((elem) => elem.field == 'email').required,
												},
												grid_layout: { xs: 12, md: 6 },
											},
											{
												id: 'language',
												name: 'language',
												label: translate('users_register_form_myprofile_field_language'),
												type:
													this.props.registration_fields.find((elem) => elem.field == 'language') &&
													this.props.registration_fields.find((elem) => elem.field == 'language').display
														? FORM_TYPES.SELECT
														: FORM_TYPES.NONE,
												options: this.props.settings.site.available_languages
													? this.props.settings.site.available_languages.map((obj) => {
															return {
																value: obj.code,
																label: translate('language_' + obj.label),
															};
													  })
													: [
															{
																label: translate('users_register_form_myprofile_field_language_option_english'),
																value: 'en-US',
															},
															{
																label: translate('users_register_form_myprofile_field_language_option_swedish'),
																value: 'sv-SE',
															},
													  ],
												validate: {
													required:
														this.props.registration_fields.find((elem) => elem.field == 'language') &&
														this.props.registration_fields.find((elem) => elem.field == 'language').required,
												},
												grid_layout: { xs: 12, md: 6 },
											},
											{
												id: 'password',
												name: 'password',
												label: translate('user_change_write_new_password'),
												type:
													this.props.registration_fields.find((elem) => elem.field == 'password') &&
													this.props.registration_fields.find((elem) => elem.field == 'password').display
														? FORM_TYPES.INPUT_ADORNMENT
														: FORM_TYPES.NONE,
												inputType: this.state.visibility ? FORM_TYPES.INPUT : FORM_TYPES.PASSWORD,
												validate: {
													required:
														this.props.registration_fields.find((elem) => elem.field == 'password') &&
														this.props.registration_fields.find((elem) => elem.field == 'password').required,
													functionValidation: [
														{
															validation: () => {
																return (
																	this.state.charNumberValid &&
																	this.state.specialCharValid &&
																	this.state.uppercaseValid &&
																	this.state.numberValid
																);
															},

															message: 'user_change_password_need_all_values_validate',
														},
													],
												},
												onChange: (value) => {
													this.handlePasswordChange(value);
												},
												adornment: {
													action: () =>
														this.setState({
															visibility: !this.state.visibility,
														}),
													triggered: false,
													alwaysEnabled: true,
													icon: this.state.visibility ? <Visibility /> : <VisibilityOff />,
												},
												grid_layout: { xs: 12, md: 6 },
												initialvalue: '',
											},
											{
												id: 'password_confirm',
												name: 'password_confirm',
												label: translate('user_change_password_write_repeat'),
												type:
													this.props.registration_fields.find((elem) => elem.field == 'password') &&
													this.props.registration_fields.find((elem) => elem.field == 'password').display
														? FORM_TYPES.PASSWORD
														: FORM_TYPES.NONE,
												validate: {
													required:
														this.props.registration_fields.find((elem) => elem.field == 'password') &&
														this.props.registration_fields.find((elem) => elem.field == 'password').required,
													functionValidation: [
														{
															validation: () => {
																return this.state.valid;
															},

															message: 'user_change_password_confirm_validate',
														},
													],
												},
												onChange: (value) => {
													this.setState({ valid: this.props.password_field === value });
												},
												grid_layout: { xs: 12, md: 6 },
												initialvalue: '',
											},
											...(this.props.settings.site.password_complexity
												? [
														{
															id: 'password_complexity',
															type: FORM_TYPES.EXTERNAL,
															content: this.getPasswordComplexityLayout(),
														},
												  ]
												: []),
											{
												id: 'consented',
												name: 'consented',
												label: translate('users_register_form_personal_data_field_consented'),
												type:
													this.props.registration_fields.find((elem) => elem.field == 'consented') &&
													this.props.registration_fields.find((elem) => elem.field == 'consented').display
														? FORM_TYPES.SINGLE_CHECKBOX
														: FORM_TYPES.NONE,
												initialvalue: this.props.user !== null ? (this.props.user.consented == '1' ? true : false) : null,
												validate: {
													required:
														this.props.registration_fields.find((elem) => elem.field == 'consented') &&
														this.props.registration_fields.find((elem) => elem.field == 'consented').required,
												},
												onChange: (value) => {
													this.setState((state) => ({
														...state,
														consented: value ? '1' : '0',
													}));
												},
											},
											{
												id: 'client_id',
												name: 'client_id',
												label: '',
												type: FORM_TYPES.HIDDEN,
												initialvalue: this.props.login.client.id,
											},
										],
									},
							  ]
							: []),
					],
				},
			],
			buttons: [
				{
					id: 'users_register_new_account_submit_button',
					label: translate('users_register_new_account_submit_button'),
					color: 'primary',
					disabled: typeof this.state.consented === 'undefined' || this.state.consented === '0',
					grid_layout: { md: 6, content_align: 'center' },
					action: () => {
						this.handleSubmitButton('newuser');
					},
				},
			],
		};

		return structure;
	};
	handleBackButton = () => {
		this.props.push('/login');
	};
	getPasswordComplexityLayout = () => {
		const { translate } = this.props;

		return (
			<Container maxWidth="lg">
				<Grid container>
					{!this.props.settings.site.password_complexity.length || !this.props.settings.site.password_complexity.length === 0 ? (
						''
					) : (
						<Grid item md={3} xs={12}>
							<Typography align="justify" variant="subtitle2" paragraph={true}>
								{this.state.charNumberValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
								{translate('user_change_password_carachters', {
									length: this.props.settings.site.password_complexity.length,
								})}
							</Typography>
						</Grid>
					)}
					{!this.props.settings.site.password_complexity.special_characters ||
					this.props.settings.site.password_complexity.special_characters === 0 ? (
						''
					) : (
						<Grid item md={3} xs={12}>
							<Typography align="justify" variant="subtitle2" paragraph={true}>
								{this.state.specialCharValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
								{translate('user_change_password_special_carachters', {
									length: this.props.settings.site.password_complexity.special_characters,
								})}
							</Typography>
						</Grid>
					)}
					{!this.props.settings.site.password_complexity.uppercase || this.props.settings.site.password_complexity.uppercase === 0 ? (
						''
					) : (
						<Grid item md={3} xs={12}>
							<Typography align="justify" variant="subtitle2" paragraph={true}>
								{this.state.uppercaseValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
								{translate('user_change_password_uppercase_letter', {
									length: this.props.settings.site.password_complexity.uppercase,
								})}
							</Typography>
						</Grid>
					)}
					{!this.props.settings.site.password_complexity.numbers || this.props.settings.site.password_complexity.numbers === 0 ? (
						''
					) : (
						<Grid item md={3} xs={12}>
							<Typography align="justify" variant="subtitle2" paragraph={true}>
								{this.state.numberValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
								{translate('user_rchange_password_number', {
									length: this.props.settings.site.password_complexity.numbers,
								})}
							</Typography>
						</Grid>
					)}
				</Grid>
			</Container>
		);
	};

	render() {
		const { translate } = this.props;
		const configuration = this.handleConfig();

		return (
			<div className={'generic-defaultlayout-main'}>
				<MessageToastLogin />
				<NavBar disabledPositionFixed={true} />
				<Container
					component="main"
					maxWidth={this.props.fullsizeContainer ? false : 'lg'}
					className="generic-defaultlayout-content"
					disableGutters={this.props.disableGutters}
				>
					<BlockUI />
					<Typography align="center" variant="h2" component="h2" gutterBottom>
						{translate('user_register_register_new_account_title')}
					</Typography>
					<Typography align="center" variant="subtitle2" component="p" gutterBottom>
						{translate('user_register_new_account_subtitle')}
					</Typography>
					<Main form={configuration.form} formData={configuration} backButton={true} handleBackButton={this.handleBackButton} />
				</Container>
			</div>
		);
	}
}
const selector = formValueSelector('newuser');
const mapStateToProps = (state) => ({
	user: state.users.whoami,
	config: state.config,
	login: state.login,
	translate: getTranslate(state.localize),
	settings: state.settings,
	password_field: selector(state, 'password'),
	password_verify: selector(state, 'password_confirm'),
	registration_fields: state.settings.users.registration_fields,
});

export default connect(mapStateToProps, { push, createNewAccount, verifySsnAction })(CreateNewAccount);
