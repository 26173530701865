import WIDGETS from './widgets';

export default {
  spacing: 0,
	direction: 'column',
	widgets: [
		{ content: WIDGETS.GOBACKFOOD, breakpoints: { xs: 12, sm: 12 }, lazy_loading: 0 },
		{ content: WIDGETS.LOGSFOOD, breakpoints: { xs: 12, sm: 12 } },
	],
};
