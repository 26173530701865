import axios from 'axios';
import { store } from '../store.js';
import { loadWhoami } from '../components/Users/actions/usersActions';
import { handleTranslationsSettingsAction, loadConfigurations, removeSettingsAction } from './settingsActions';
import { initializeTranslations } from './translationsActions';
import { setPromptDialogIsDirty } from '../components/Generic/actions/promptActions';
import { fetchTicketbyTokenAction, postTicketAction } from '../components/Tickets/actions/ticketsActions.js';
import { fetchActivitybyTokenAction } from '../components/Activities/actions/activitiesActions.js';
import { push } from 'connected-react-router';
import { handleSplashScreenAction } from '../components/Generic/actions/genericActions.js';
import { setWebsocketToken } from '../components/Chat/actions/websocketsActions.js';

export const initializeAction = () => async (dispatch) => {
	const state = store.getState();

	const responseConfiguration = await dispatch(loadConfigurations());

	const responseInitializeTranslations = await dispatch(initializeTranslations(responseConfiguration));

	await dispatch(handleTranslationsSettingsAction(responseInitializeTranslations));

	try {
		await dispatch(loadWhoami(true));

		if (!state.login.login || state.router.location.search.length !== 0) {
			await dispatch(handleRedirect(false));

			await dispatch(
				removeSettingsAction(['tickets', 'activities', 'conditions_id', 'conditions', 'practitioners_id', 'starts_at', 'ends_at', 'token', 'messages_id'])
			);
		}
	} catch (error) {
		return dispatch(handleSplashScreenAction(false));
	}

	return dispatch(handleSplashScreenAction(false));
};

export const handleRedirect =
	(force = true) =>
	async (dispatch) => {
		const state = store.getState();

    let onlytoken = false;
    if (state.settings.params !== null) {
      if (Object.keys(state.settings.params).length === 1 && typeof state.settings.params.token !== 'undefined') {
        onlytoken = true;
        await dispatch(removeSettingsAction(['token']));
      }
    }

		if (state.users.whoami.status === '10') {
			return await Promise.all([
				dispatch(push('/myprofile')),
				...(window.self === window.top
					? [
							dispatch(
								setPromptDialogIsDirty({
									is_dirty: true,
									path_allowed: ['/myprofile'],
									prompt_dialog_title: 'prompt_user_incomplete_title',
									prompt_dialog_description: 'prompt_user_incomplete_description',
									allow_resume: false,
								})
							),
					  ]
					: []),
			]);
		} else if (state.router.location?.state?.referrer) {
			return await dispatch(push(state.router.location?.state?.referrer + state.router.location?.search));
		} else if (state.settings.params !== null && typeof state.settings.params.tickets !== 'undefined') {
			await dispatch(fetchTicketbyTokenAction(state.settings.params.tickets, true));
			return await dispatch(
				removeSettingsAction(['tickets', 'activities', 'conditions_id', 'conditions', 'practitioners_id', 'starts_at', 'ends_at', 'token', 'messages_id'])
			);
		} else if (state.settings.params !== null && typeof state.settings.params.activities !== 'undefined') {
			await dispatch(fetchActivitybyTokenAction(state.settings.params.activities));
			return await dispatch(
				removeSettingsAction(['tickets', 'activities', 'conditions_id', 'conditions', 'practitioners_id', 'starts_at', 'ends_at', 'token', 'messages_id'])
			);
		} else if (state.settings.params !== null && typeof state.settings.params.conditions_id !== 'undefined') {
			await dispatch(postTicketAction(null, state.settings.params));
			return await dispatch(
				removeSettingsAction(['tickets', 'activities', 'conditions_id', 'conditions', 'practitioners_id', 'starts_at', 'ends_at', 'token', 'messages_id'])
			);
		} else if (state.settings.params !== null && typeof state.settings.params.messages_id !== 'undefined') {
			// TODO: Change to flow like clicking on notification from bell
      await dispatch(push('/dashboard'));
			return await dispatch(
				removeSettingsAction(['tickets', 'activities', 'conditions_id', 'conditions', 'practitioners_id', 'starts_at', 'ends_at', 'token', 'messages_id'])
			);
		} else {
			if (
				state.router.location.pathname !== '/' &&
				state.router.location.pathname !== '/login' &&
				state.router.location.pathname !== '/backoffice' &&
				!force
			) {
				return;
			} else if (state.login.self_registration && (state.settings.params == null || onlytoken)) {
				return await dispatch(push('/conditions'));
			} else if (state.settings.feature.dashboard && (state.settings.params == null || onlytoken) && state.users.whoami.redirect_url === null) {
				return await dispatch(push('/dashboard'));
			} else if (state.settings.feature.dashboard && (state.settings.params == null || onlytoken) && state.users.whoami.redirect_url !== null) {
				return await dispatch(push(state.users.whoami.redirect_url));
			} else if (state.users.whoami.scopes.includes('lists.all_cases')) {
				return await dispatch(push('/allcases'));
			} else if (state.users.whoami.scopes.includes('practitioner')) {
				return await dispatch(push('/mycases'));
			} else if (state.users.whoami.scopes.includes('customer')) {
				if (state.settings.feature.dashboard && (state.settings.params == null || onlytoken) && state.users.whoami.redirect_url === null) {
					return await dispatch(push('/dashboard'));
				} else if (state.settings.feature.dashboard && (state.settings.params == null || onlytoken) && state.users.whoami.redirect_url !== null) {
					return await dispatch(push(state.users.whoami.redirect_url));
				} else if (
					(state.settings.params !== null && typeof state.settings.params.conditions !== 'undefined') ||
					state.users.whoami.ticket_count === 0
				) {
					return await dispatch(push('/conditions'));
				} else {
					return await dispatch(push('/mycases'));
				}
			} else {
				return await dispatch(push('/mycases'));
			}
		}
	};
