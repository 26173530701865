import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Field, reduxForm, getFormSyncErrors, formValueSelector, untouch, updateSyncErrors } from 'redux-form';
import withTheme from '@mui/styles/withTheme';
import {
	Container,
	Button,
	Grid,
	Typography,
	MenuItem,
	FormControl,
	FormLabel,
	FormGroup,
	FormControlLabel,
	Radio,
	ListSubheader,
	FormHelperText,
	IconButton,
	Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import {
	RenderTextField,
	RenderRadioGroup,
	RenderDatePicker,
	RenderTimePicker,
	RenderDateTimePicker,
	RenderSlider,
	RenderImageUpload,
	RenderAutocomplete,
	RenderMultiAutocomplete,
	RenderLink,
	RenderCheckboxGroup,
	RenderSingleCheckbox,
	RenderInputAdornment,
	RenderWysiwyg,
	RenderFileField,
	RenderTreeSelect,
	RenderTextFieldMultiple,
} from './FormTypes';

import Loading from './Loading';
import { bytesToMegaBytes } from './../../helpers/functions/functions';
import { disableFormButtonAction, addDelayAction } from './actions/genericActions';

// TODO: Add possibility to add defaultValues and Placeholders
// also change number of rows in textareas for instance
// add helpertext in each inputs and also in FORM (general)

const renderChildOptions = (options) => {
	const items = [];

	options.forEach((group, key) => {
		items.push(
			<ListSubheader disableSticky={true} key={key}>
				{group.parent}
			</ListSubheader>
		);
		group.options.forEach((child) => {
			items.push(
				<MenuItem key={child.value} value={child.value} className="generic-form-content-form-field-select-group-child">
					{child.label}
				</MenuItem>
			);
		});
	});
	return items;
};

class Form extends Component {
	constructor(props) {
		super(props);
		// create a ref to store the refInput DOM element
		this.refInput = React.createRef();
		this.focusRefInput = this.focusRefInput.bind(this);

		this.state = {
			clicked_button: false,
		};
	}

	resetFields = () => {
		if (this.imageRef) {
			this.imageRef.handleClearState();
		}
	};

	focusRefInput() {
		// Explicitly focus the text input using the raw DOM API
		// Note: we're accessing "current" to get the DOM node
		this.refInput.current.focus();
	}

	render() {
		const required = (value) =>
			(value && ((typeof value === 'string' && value.trim().length > 0) || (Array.isArray(value) && value.length > 0))) ||
			value instanceof Date ||
			Number.isInteger(value)
				? undefined
				: this.props.translate('form_field_required');

		const minRequired = (min) => (value) => value >= min ? undefined : this.props.translate('form_field_invalid');
		const regexValidate = (regex) => (value) =>
			typeof value === 'undefined' ? undefined : value.match(regex) ? undefined : this.props.translate('form_field_invalid');
		const functionValidation = (validationFunction, message) => (value) => validationFunction(value) ? undefined : this.props.translate(message);
		const email = (value) =>
			typeof value === 'undefined' || value === null || value.length === 0
				? undefined
				: value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g)
				? undefined
				: this.props.translate('form_field_invalid_email');
		const atLeastOneFieldRequired = (requiredFields, fieldLabel) => (value, allValues) => {
			if ((value && value.length > 0) || value instanceof Date || Number.isInteger(value)) {
				return undefined;
			} else {
				let flag = false;

				requiredFields.forEach((field) => {
					if (
						(allValues[field.id] && allValues[field.id].length > 0) ||
						allValues[field.id] instanceof Date ||
						Number.isInteger(allValues[field.id])
					) {
						flag = true;
					}
				});
				if (flag) {
					return undefined;
				} else {
					return (
						this.props.translate('form_field_at_least_one_field_required') +
						': ' +
						fieldLabel +
						', ' +
						requiredFields.map((field, index) => field.label + (index + 1 !== requiredFields.length ? ', ' : ''))
					);
				}
			}
		};
		const maxSizeFile = (max) => (value) => {
			if (value && value.length > 0 && bytesToMegaBytes(value[0].size) >= max.replace('M', ' ')) {
				return this.props.translate('form_field_bigger_maxsize');
			} else {
				return undefined;
			}
		};

		const fileTypeReject = (file) => (value) => {
			if (value && value.length > 0 && (value[0].type === '' || value[0].type === file)) {
				return this.props.translate('form_field_mime_type_invalid');
			} else {
				return undefined;
			}
		};

		const videoResolutionsAllowed = (width, height) => (field, allowed_resolutions) => {
			console.log(width, height);
			console.log(field);
			console.log(allowed_resolutions);
			let aspect_ok = false;
			console.log(this.props);

			allowed_resolutions.forEach((elem) => {
				if (width === elem[0] && height === elem[1]) {
					aspect_ok = true;
				}
			});

			if (!aspect_ok) {
				this.props.invalidateField(this.props.formData.form, {
					[field]: this.props.translate('video_upload_error_resolution'),
				});
			}
		};

		const handleInputType = (field, i) => {
			switch (field.type) {
				case 'input':
				case 'password':
				case 'number':
					return (
						<Field
							ref={this.refInput}
							name={field.name}
							component={RenderTextField}
							inputdata={field}
							{...(field.validate && {
								validate: [
									...(field.validate.regexValidate ? [regexValidate(field.validate.regexValidate)] : []),
									...(field.validate.required ? [required] : []),
									...(typeof field.validate.atLeastOneFieldRequired !== 'undefined'
										? [atLeastOneFieldRequired(field.validate.atLeastOneFieldRequired, field.label)]
										: []),
									...(field.validate.email ? [email] : []),
									...(typeof field.validate.minRequired !== 'undefined' ? [minRequired(field.validate.minRequired)] : []),
									...(field.validate.functionValidation && field.validate.functionValidation.length > 0
										? field.validate.functionValidation.map((f) =>
												functionValidation(f.validation, f.message ? f.message : 'form_field_invalid')
										  )
										: []),
								],
							})}
						/>
					);
				case 'hidden':
					return (
						<div key={i}>
							<Field
								ref={this.refInput}
								name={field.name}
								component={RenderTextField}
								inputdata={field}
								type="hidden"
								style={{ display: 'none' }}
							/>
						</div>
					);
				case 'radiobutton':
					return (
						<Field
							name={field.name}
							component={RenderRadioGroup}
							inputdata={field}
							ref={this.refInput}
							{...(field.validate && {
								validate: [
									...(field.validate.regexValidate ? [regexValidate(field.validate.regexValidate)] : []),
									...(field.validate.required ? [required] : []),
									...(field.validate.functionValidation && field.validate.functionValidation.length > 0
										? field.validate.functionValidation.map((f) =>
												functionValidation(f.validation, f.message ? f.message : 'form_field_invalid')
										  )
										: []),
								],
							})}
						/>
					);
				case 'checkbox':
					return (
						<FormControl component="fieldset">
							<FormLabel component="legend">
								{field.label}
								{field.infoTooltip && (
									<Tooltip title={field.infoTooltip} placement="right-end" color="secondary">
										<InfoIcon size="small" />
									</Tooltip>
								)}
							</FormLabel>
							<FormGroup>
								<RenderCheckboxGroup
									name={field.name}
									inputdata={field}
									options={field.options}
									onChangeValue={field.onChange}
									ref={this.refInput}
								/>
							</FormGroup>
						</FormControl>
					);
				case 'singleCheckbox':
					return <Field ref={this.refInput} name={field.name} inputdata={field} component={RenderSingleCheckbox} />;
				case 'select':
					return (
						<Field
							ref={this.refInput}
							name={field.name}
							component={RenderTextField}
							inputdata={field}
							select
							{...(field.validate && {
								validate: [
									...(field.validate.regexValidate ? [regexValidate(field.validate.regexValidate)] : []),
									...(field.validate.required ? [required] : []),
									...(field.validate.functionValidation && field.validate.functionValidation.length > 0
										? field.validate.functionValidation.map((f) =>
												functionValidation(f.validation, f.message ? f.message : 'form_field_invalid')
										  )
										: []),
								],
							})}
						>
							<MenuItem value="">
								<em>-</em>
							</MenuItem>
							{field.options.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Field>
					);
				case 'select_multiple_checkbox':
					return (
						<Field
							ref={this.refInput}
							name={field.name}
							component={RenderTextFieldMultiple}
							inputdata={field}
							select
							{...(field.validate && {
								validate: [
									...(field.validate.regexValidate ? [regexValidate(field.validate.regexValidate)] : []),
									...(field.validate.required ? [required] : []),
									...(field.validate.functionValidation && field.validate.functionValidation.length > 0
										? field.validate.functionValidation.map((f) =>
												functionValidation(f.validation, f.message ? f.message : 'form_field_invalid')
										  )
										: []),
								],
							})}
						/>
					);
				case 'select_group':
					return (
						<Field
							ref={this.refInput}
							name={field.name}
							component={RenderTextField}
							inputdata={field}
							select
							{...(field.validate && {
								validate: [
									...(field.validate.regexValidate ? [regexValidate(field.validate.regexValidate)] : []),
									...(field.validate.required ? [required] : []),
									...(field.validate.functionValidation && field.validate.functionValidation.length > 0
										? field.validate.functionValidation.map((f) =>
												functionValidation(f.validation, f.message ? f.message : 'form_field_invalid')
										  )
										: []),
								],
							})}
						>
							<MenuItem value="">
								<em>-</em>
							</MenuItem>
							{renderChildOptions(field.options)}
						</Field>
					);
				case 'select_tree':
					return (
						<Field
							ref={this.refInput}
							name={field.name}
							component={RenderTreeSelect}
							inputdata={field}
							select
							{...(field.validate && {
								validate: [
									...(field.validate.regexValidate ? [regexValidate(field.validate.regexValidate)] : []),
									...(field.validate.required ? [required] : []),
									...(field.validate.functionValidation && field.validate.functionValidation.length > 0
										? field.validate.functionValidation.map((f) =>
												functionValidation(f.validation, f.message ? f.message : 'form_field_invalid')
										  )
										: []),
								],
							})}
						/>
					);
				case 'autocomplete_textfield':
					return (
						<Field
							ref={this.refInput}
							name={field.name}
							component={RenderAutocomplete}
							inputdata={field}
							{...(field.validate && {
								validate: [
									...(field.validate.regexValidate ? [regexValidate(field.validate.regexValidate)] : []),
									...(field.validate.required ? [required] : []),
									...(field.validate.functionValidation && field.validate.functionValidation.length > 0
										? field.validate.functionValidation.map((f) =>
												functionValidation(f.validation, f.message ? f.message : 'form_field_invalid')
										  )
										: []),
								],
							})}
						/>
					);
				case 'multi_autocomplete_textfield':
					return (
						<Field
							ref={this.refInput}
							name={field.name}
							component={RenderMultiAutocomplete}
							inputdata={field}
							{...(field.validate && {
								validate: [
									...(field.validate.regexValidate ? [regexValidate(field.validate.regexValidate)] : []),
									...(field.validate.required ? [required] : []),
									...(field.validate.functionValidation && field.validate.functionValidation.length > 0
										? field.validate.functionValidation.map((f) =>
												functionValidation(f.validation, f.message ? f.message : 'form_field_invalid')
										  )
										: []),
								],
							})}
						/>
					);
				case 'textarea':
					return (
						<Field
							ref={this.refInput}
							name={field.name}
							component={RenderTextField}
							inputdata={field}
							multiline={true}
							minRows={4}
							{...(field.validate && {
								validate: [
									...(field.validate.regexValidate ? [regexValidate(field.validate.regexValidate)] : []),
									...(field.validate.required ? [required] : []),
									...(field.validate.functionValidation && field.validate.functionValidation.length > 0
										? field.validate.functionValidation.map((f) =>
												functionValidation(f.validation, f.message ? f.message : 'form_field_invalid')
										  )
										: []),
								],
							})}
						/>
					);
				case 'datepicker':
					return (
						<Field
							ref={this.refInput}
							name={field.name}
							component={RenderDatePicker}
							inputdata={field}
							locale={this.props.language}
							{...(field.validate && {
								validate: [
									...(field.validate.regexValidate ? [regexValidate(field.validate.regexValidate)] : []),
									...(field.validate.required ? [required] : []),
									...(field.validate.functionValidation && field.validate.functionValidation.length > 0
										? field.validate.functionValidation.map((f) =>
												functionValidation(f.validation, f.message ? f.message : 'form_field_invalid')
										  )
										: []),
								],
							})}
						/>
					);
				case 'timepicker':
					return (
						<Field
							ref={this.refInput}
							name={field.name}
							component={RenderTimePicker}
							inputdata={field}
							locale={this.props.language}
							{...(field.validate && {
								validate: [
									...(field.validate.regexValidate ? [regexValidate(field.validate.regexValidate)] : []),
									...(field.validate.required ? [required] : []),
									...(field.validate.functionValidation && field.validate.functionValidation.length > 0
										? field.validate.functionValidation.map((f) =>
												functionValidation(f.validation, f.message ? f.message : 'form_field_invalid')
										  )
										: []),
								],
							})}
						/>
					);
				case 'datetimepicker':
					return (
						<Field
							ref={this.refInput}
							name={field.name}
							component={RenderDateTimePicker}
							inputdata={field}
							locale={this.props.language}
							timezone={this.props.timezone}
							{...(field.validate && {
								validate: [
									...(field.validate.regexValidate ? [regexValidate(field.validate.regexValidate)] : []),
									...(field.validate.required ? [required] : []),
									...(field.validate.functionValidation && field.validate.functionValidation.length > 0
										? field.validate.functionValidation.map((f) =>
												functionValidation(f.validation, f.message ? f.message : 'form_field_invalid')
										  )
										: []),
								],
							})}
						/>
					);
				case 'slider':
					return (
						<Field
							ref={this.refInput}
							name={field.name}
							component={RenderSlider}
							inputdata={field}
							requiredLabel={this.props.translate('form_field_required')}
							{...(field.validate && {
								validate: [
									...(field.validate.regexValidate ? [regexValidate(field.validate.regexValidate)] : []),
									...(field.validate.required ? [required] : []),
									...(typeof field.validate.minRequired !== 'undefined' ? [minRequired(field.validate.minRequired)] : []),
									...(field.validate.functionValidation && field.validate.functionValidation.length > 0
										? field.validate.functionValidation.map((f) =>
												functionValidation(f.validation, f.message ? f.message : 'form_field_invalid')
										  )
										: []),
								],
							})}
						/>
					);
				case 'dropzone':
					return (
						<Field
							name={field.name}
							component={RenderImageUpload}
							inputdata={field}
							type="file"
							onRef={(ref) => (this.imageRef = ref)}
							props={
								typeof field.validate.videoResolutionsAllowed !== 'undefined' && {
									videoResolutionsAllowed: (width, height) => {
										console.log(width, height);
										console.log(field.name, field.validate.videoResolutionsAllowed);
										videoResolutionsAllowed(width, height)(field.name, field.validate.videoResolutionsAllowed);
									},
								}
							}
							{...(field.validate && {
								validate: [
									...(field.validate.required ? [required] : []),
									...(typeof field.validate.maxSizeFile !== 'undefined' ? [maxSizeFile(field.validate.maxSizeFile)] : []),
									...(typeof field.validate.fileTypeReject !== 'undefined' ? [fileTypeReject(field.validate.fileTypeReject)] : []),
								],
							})}
						/>
					);
				case 'wysiwyg':
					return <Field name={field.name} component={RenderWysiwyg} inputdata={field} />;
				case 'link':
					return <Field ref={this.refInput} name={field.name} component={RenderLink} inputdata={field} />;
				case 'inputadornment':
					return (
						<Field
							ref={this.refInput}
							name={field.name}
							type={field.inputType}
							component={RenderInputAdornment}
							inputdata={field}
							{...(field.validate && {
								validate: [
									...(field.validate.regexValidate ? [regexValidate(field.validate.regexValidate)] : []),
									...(field.validate.required ? [required] : []),
									...(field.validate.functionValidation && field.validate.functionValidation.length > 0
										? field.validate.functionValidation.map((f) =>
												functionValidation(f.validation, f.message ? f.message : 'form_field_invalid')
										  )
										: []),
								],
							})}
						/>
					);
				case 'external':
					return field.content;
				default:
					return null;
			}
		};

		return (
			<Container
				component="main"
				maxWidth={this.props.formData.maxWidth ? this.props.formData.maxWidth : 'sm'}
				disableGutters={this.props.disableGutters}
				className="generic-form-main"
			>
				<Typography variant="h5" align="center" gutterBottom>
					{this.props.formData.title}
				</Typography>
				<form
					onKeyDown={(event) => {
						if (this.props.formData.disable_submit_on_enter && event.key == 'Enter') {
							event.preventDefault();
							return false;
						}
					}}
					{...(this.props.formData.id && { id: this.props.formData.id })}
					autoComplete="off"
					onSubmit={(event) => {
						event.preventDefault();

						if (typeof this.props.formData.buttons !== 'undefined' && this.props.formData.buttons.length > 0) {
							let clickedButton = this.props.formData.buttons.find((button) => button.id === this.state.clicked_button);

							let newFormErrors = { ...this.props.formErrors };

							if (
								Object.keys(newFormErrors).length > 0 &&
								typeof clickedButton.exclude_fields_validation !== 'undefined' &&
								clickedButton.exclude_fields_validation.length > 0
							) {
								clickedButton.exclude_fields_validation.forEach((exclude_field_validation) => {
									delete newFormErrors[exclude_field_validation];
									this.props.untouch(this.props.formData.id, exclude_field_validation);
								});
							}

							if (!this.props.invalid || Object.keys(newFormErrors).length === 0) {
								this.props.disableFormButtonAction(true);

								if (clickedButton.action) {
									clickedButton.action();
								}

								if (
									typeof this.props.formData.auto_enable_submit_button === 'undefined' ||
									this.props.formData.auto_enable_submit_button
								) {
									this.props.addDelayAction(() => this.props.disableFormButtonAction(false));
								}

								setTimeout(() => {
									this.resetFields();
								}, 4000);
							} else {
								this.props.touch(...Object.keys(newFormErrors));
								document.getElementById(Object.keys(newFormErrors)[0]).focus();
							}
						} else {
							if (!this.props.invalid) {
								this.props.disableFormButtonAction(true);
								this.props.handleSubmit();

								/*TODO: remove the disable button from form and implement on each submit action instead */
								if (
									typeof this.props.formData.auto_enable_submit_button === 'undefined' ||
									this.props.formData.auto_enable_submit_button
								) {
									this.props.addDelayAction(() => this.props.disableFormButtonAction(false));
								}

								setTimeout(() => {
									this.resetFields();
								}, 4000);
							} else {
								this.props.touch(...Object.keys(this.props.formErrors));
								if (document.getElementById(Object.keys(this.props.formErrors)[0])) {
									document.getElementById(Object.keys(this.props.formErrors)[0]).focus();
								}
							}
						}
					}}
					className="generic-form-content-form"
				>
					<Grid container>
						<Grid
							container
							item
							spacing={2}
							xs={this.props.formData.fields_grid_layout ? this.props.formData.fields_grid_layout.xs : 12}
							md={this.props.formData.fields_grid_layout ? this.props.formData.fields_grid_layout.md : 12}
							{...(this.props.formData.fields_grid_layout && this.props.formData.fields_grid_layout.content_align
								? { alignItems: this.props.formData.fields_grid_layout.content_align }
								: {})}
						>
							{this.props.formData.fields &&
								this.props.formData.fields.map((field, i) =>
									field.type !== 'hidden' && field.type !== 'none' ? (
										<Grid
											item
											xs={field.grid_layout ? field.grid_layout.xs : 12}
											md={field.grid_layout ? field.grid_layout.md : 12}
											{...(field.grid_layout && field.grid_layout.lg ? { lg: field.grid_layout.lg } : {})}
											key={i}
										>
											{' '}
											{handleInputType(field, i)}{' '}
										</Grid>
									) : (
										handleInputType(field, i)
									)
								)}
							{this.props.formData.helperText &&
								this.props.formData.helperText.map((helper, i) => (
									<Grid key={i} item xs={12}>
										<Typography variant="body2" gutterBottom>
											{helper.text}
										</Typography>
									</Grid>
								))}
						</Grid>
						{!this.props.hideButton &&
							(this.props.formData.buttons && this.props.formData.buttons.length > 0 ? (
								<Grid
									container
									item
									spacing={this.props.formData.buttons_grid_layout ? this.props.formData.buttons_grid_layout.spacing : 2}
									xs={this.props.formData.buttons_grid_layout ? this.props.formData.buttons_grid_layout.xs : 12}
									md={this.props.formData.buttons_grid_layout ? this.props.formData.buttons_grid_layout.md : 12}
									{...(this.props.formData.buttons_grid_layout && this.props.formData.buttons_grid_layout.content_align
										? { alignItems: this.props.formData.buttons_grid_layout.content_align }
										: {})}
								>
									{this.props.formData.buttons.map((button, i) => {
										let ButtonComponent = Button;

										if (button.type == 'iconButton') {
											ButtonComponent = IconButton;
										}

										return (
											!button.hidden && (
												<Grid
													item
													xs={button.grid_layout ? button.grid_layout.xs : 12}
													md={button.grid_layout ? button.grid_layout.md : 12}
													key={i}
													{...(button.grid_layout && button.grid_layout.content_align
														? { style: { textAlign: button.grid_layout.content_align } }
														: {})}
												>
													<ButtonComponent
														id={button.id}
														name={button.id}
														type={button.submit || typeof button.submit == 'undefined' ? 'submit' : 'button'}
														onClick={() => {
															this.setState(
																{
																	clicked_button: button.id,
																},
																() => {
																	if (typeof button.submit !== 'undefined' && !button.submit) {
																		button.action();
																	}
																}
															);
														}}
														{...(typeof button.fullWidth !== 'undefined'
															? { fullWidth: button.fullWidth }
															: { fullWidth: true })}
														{...(button.variant ? { variant: button.variant } : { variant: 'contained' })}
														{...(button.size ? { size: button.size } : {})}
														{...(button.color ? { color: button.color } : { color: 'primary' })}
														{...(button.startIcon ? { startIcon: button.startIcon } : {})}
														{...(button.endIcon ? { endIcon: button.endIcon } : {})}
														{...(button.disabled ? { disabled: button.disabled } : {})}
														{...(button.customClass ? { className: button.customClass } : {})}
													>
														{typeof button.type !== 'undefined' && button.type == 'iconButton'
															? button.icon
															: this.props.translate(button.label)}
													</ButtonComponent>
												</Grid>
											)
										);
									})}
								</Grid>
							) : (
								<Container
									disableGutters={true}
									component="main"
									maxWidth={this.props.formData.maxWidthButton ? this.props.formData.maxWidthButton : 'sm'}
								>
									<Button
										{...(this.props.buttonId && { id: this.props.buttonId })}
										fullWidth
										variant="contained"
										type="submit"
										color={'primary'}
										className="generic-form-content-form-button"
										disabled={this.props.actionDisabled || this.props.disable_button}
									>
										{this.props.disable_button ? (
											<Loading button={true} insideComponent={true} size={this.props.theme.spacing(2)} />
										) : null}
										{this.props.disable_button ? (
											<div style={{ color: this.props.theme.palette.primary.main }}>
												{this.props.formData.button_loading_label
													? this.props.formData.button_loading_label
													: this.props.translate('generic_form_loading_button')}
											</div>
										) : this.props.formData.button_label ? (
											this.props.formData.button_label
										) : (
											this.props.translate('generic_button_save')
										)}
									</Button>
								</Container>
							))}
					</Grid>
				</form>
				{this.props.backButton && (
					<Container
						disableGutters={true}
						component="main"
						maxWidth={
							this.props.formData.backButtonWidth
								? this.props.formData.backButtonWidth
								: this.props.formData.maxWidth
								? this.props.formData.maxWidth
								: 'sm'
						}
					>
						<Button
							fullWidth
							color="neutral"
							variant="contained"
							className="generic-form-content-form-back-button"
							onClick={this.props.handleBackButton}
						>
							{this.props.translate('generic_button_back')}
						</Button>
					</Container>
				)}
			</Container>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	translate: getTranslate(state.localize),
	config: state.config,
	disable_button: state.generic.form.disable_button,
	login: state.login,
	language: state.users.whoami.language,
	formErrors: getFormSyncErrors(ownProps.form)(state),
	timezone: state.users.whoami.timezone,
});

const mapDispatchToProps = (dispatch) => {
	return {
		disableFormButtonAction,
		addDelayAction,
		untouch,
		invalidateField: (form, error) => dispatch(updateSyncErrors(form, error)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		destroyOnUnmount: false,
		multipartForm: true,
		enableReinitialize: true,
	})(withTheme(Form))
);
